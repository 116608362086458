<template>
  <v-container fluid>
    <v-row>
      <v-col
        md="6"
        cols="12"
      >
        <base-material-card
          color="primary"
          icon="mdi-vector-square-edit"
          inline
          class="px-5 py-3"
          style="min-height: 520px"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light">
              Extensão Geográfica :: {{ company.name }}
            </div>
          </template>
          <v-row class="mt-5">
            <v-col cols="12">
              <v-alert
                dense
                outlined
                type="info"
                color="blue"
              >
                Configure os limites geográficos dos ativos da distribuidora
                {{ company.name }} definidos pela ANEEL.
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                label="Longitude / X Mínimo"
                outlined
                type="number"
                step="0.00000001"
                v-model="company.minx"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Longitude / X Máximo"
                outlined
                type="number"
                step="0.00000001"
                v-model="company.maxx"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                label="Latitude / Y Mínimo"
                outlined
                type="number"
                step="0.00000001"
                v-model="company.miny"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="Latitude / Y Máximo"
                outlined
                type="number"
                step="0.00000001"
                v-model="company.maxy"
              />
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
      <v-col
        md="6"
        cols="12"
      >
        <base-material-card
          color="primary"
          icon="mdi-map"
          inline
          class="px-5 py-3"
          style="min-height: 520px"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light">Extensão no mapa</div>
          </template>
          <v-row class="mt-5">
            <v-col cols="12">
              <geographic-extent-on-map :company="company" />
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn
          color="primary"
          min-width="100"
          style="float: right"
          class="mt-n5 mr-0"
          @click="save()"
          :disabled="btnSaveDisabled"
          :loading="loading"
        >
          Salvar
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CompanyService from '@/services/CompanyService';
import routes from '@/store/modules/routes';

export default {
  name: 'ExtensaoGeografica',
  components: {
    GeographicExtentOnMap: () =>
      import('@/components/general/ShowCompanyGeographicExtentOnMap.vue')
  },
  data: () => ({
    company: { name: '' },
    loading: false
  }),
  mounted() {
    this.getCompany(this.$route.params.id);
  },
  computed: {
    btnSaveDisabled() {
      const { minx, maxx, miny, maxy } = this.company;
      return !(Number(minx) && Number(maxx) && Number(miny) && Number(maxy));
    }
  },
  methods: {
    getCompany(id) {
      CompanyService.getCompanyById(id)
        .then((response) => (this.company = response))
        .catch(() =>
          this.$toast.error('Erro ao buscar os dados da empresa.', '', {
            position: 'topRight'
          })
        );
    },
    save() {
      this.loading = true;
      const { minx, maxx, miny, maxy } = this.company;
      CompanyService.saveGeographicExtent(this.company.id, {
        minx,
        maxx,
        miny,
        maxy
      })
        .then(() => {
          this.$toast.success('Extensão Geográfica salva com sucesso.', '', {
            position: 'topRight'
          });
          this.$router.replace({
            name: routes.state.appRoutes['EMPRESAS_LISTAR']
          });
        })
        .catch(() =>
          this.$toast.error(
            'Erro ao salvar a extensão geográfica da empresa.',
            '',
            {
              position: 'topRight'
            }
          )
        )
        .finally(() => (this.loading = false));
    }
  }
};
</script>
